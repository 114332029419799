h1 {
  font-family: "Berkshire Swash", cursive;
  text-align: right;
  width: 100%;
  font-size: 105px;
  letter-spacing: 5px;
  margin: 0;
  filter: drop-shadow(10px 10px 4px #16171b);
}

h2 {
  font-family: "Berkshire Swash", cursive;
  text-align: right;
  width: 100%;
  font-size: 70px;
  letter-spacing: 5px;
  margin: 0;
  filter: drop-shadow(10px 10px 4px #16171b);
}

.header-cards {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 0;
  gap: 20px;
}

.flex-colum-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-card-icon {
  height: 50px;
  width: auto;
}

.header-card {
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 20px;
  gap: 20px;
  align-items: center;
  filter: drop-shadow(10px 10px 4px #16171b);
}

.header {
  margin-top: 200px;
  align-self: end;
}

@media (max-width: 900px) {
  h1 {
    font-size: 80px;
    text-align: center;
  }

  h2 {
    font-size: 50px;
    text-align: center;
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
  }
  .header-cards {
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 700px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 45px;
  }
}
