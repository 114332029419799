.help-icon {
  height: 50px;
  width: auto;
}

.help-list-item {
  display: flex;
  flex-direction: column;
  width: 130px;
  text-align: center;
  align-items: center;
  gap: 3px;
}

.help-disclaimer {
  width: 100%;
  text-align: center;
}

.help-list {
  display: flex;
  justify-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.more-icon {
  width: 10px;
  height: auto;
}

.more-icon:hover {
  filter: invert(100%);
}

.more-card {
  background-color: rgba(255, 255, 255, 0.85);
  color: #16171b;
  border-radius: 20px;
  filter: drop-shadow(2px 2px 2px #16171b);
}

.food {
  padding: 20px;
}

.drinks {
  padding: 20px;
}

.art {
  padding: 20px;
}

.altar {
  padding: 20px;
}

.funds {
  width: 100%;
  display: flex;
  gap: 30px;

  padding: 20px;
}

.payment-links {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.qr-code {
  height: 175px;
  width: auto;
}
