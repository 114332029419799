@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Cormorant+Upright:wght@300;400;700&display=swap");
@import url(/src/styles/reset.css);
@import url(/src/styles/header.css);
@import url(/src/styles/rsvp.css);
@import url(/src/styles/help.css);

.App {
  overflow-x: hidden;
  overflow-y: auto;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./images/summer-solstice-invite-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
}

/* Rest of your existing CSS code */

/* Rest of your existing CSS code */

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto;
  width: 1200px;
}

h3 {
  font-family: "Berkshire Swash", cursive;
  text-align: center;
  width: 100%;
  margin: 0;
  color: #16171b;
}

h4 {
  font-family: "Cormorant Upright", serif;
  text-align: center;
  width: 100%;
  margin: 0;
  color: #16171b;
}

p {
  font-family: "Cormorant Upright", serif;
  color: #16171b;
  margin: 0;
  font-weight: 700;
}

.card {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(10px 10px 4px #16171b);
  gap: 10px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px;
  grid-auto-rows: min-content;
  width: 100%;
  grid-gap: 20px;
}

.contact-icon {
  height: 15px;
  width: auto;
}

.flex {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

ul {
  list-style: none;
  font-family: "Cormorant Upright", serif;
  color: #16171b;
  font-weight: 700;
  padding-left: 0;
}

.description {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 1;
  grid-row-end: span 14;
}

.wear {
  grid-row-start: 15;
  grid-row-end: span 8;
  grid-column-start: 2;
  grid-column-end: span 1;
}

.activities {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 15;
  grid-row-end: span 8;
}

.rsvp {
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 14;
}

.contact {
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 15;
  grid-row-end: span 5;
}

.addto {
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 20;
  grid-row-end: span 3;
}

.help {
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-start: 23;
  grid-row-end: span 10;
}

.list-item-more {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1200px) {
  .App-header {
    width: 900px;
  }

  .description {
    grid-row-end: span 16;
  }

  .wear {
    grid-row-start: 17;
    grid-row-end: span 11;
  }

  .activities {
    grid-row-start: 17;
    grid-row-end: span 11;
  }

  .addto {
    grid-row-start: 21;
    grid-row-end: span 4;
  }

  .help {
    grid-row-start: 28;
    grid-row-end: span 10;
  }

  .rsvp {
    grid-row-end: span 15;
  }

  .contact {
    grid-row-start: 16;
  }
}

@media (max-width: 900px) {
  .App-header {
    width: 700px;
  }

  .description {
    grid-row-end: span 22;
  }

  .wear {
    grid-row-start: 31;
    grid-row-end: span 10;
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  .activities {
    grid-row-start: 23;
    grid-row-end: span 8;

    grid-column-end: span 2;
  }

  .help {
    grid-row-start: 41;
  }
}

@media (max-width: 700px) {
  .App-header {
    width: 500px;
  }

  .description {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 1;
    grid-row-end: span 19;
  }

  .wear {
    grid-row-start: 28;
    grid-row-end: span 6;
    grid-column-start: 1;
    grid-column-end: span 3;
  }

  .activities {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 20;
    grid-row-end: span 8;
  }

  .rsvp {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 34;
    grid-row-end: span 14;
  }

  .contact {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 48;
    grid-row-end: span 5;
  }

  .addto {
    grid-column-start: 3;
    grid-column-end: span 1;
    grid-row-start: 48;
    grid-row-end: span 3;
  }

  .help {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 53;
    grid-row-end: span 10;
  }
}

@media (max-width: 500px) {
  .App-header {
    width: 300px;
  }

  .description {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 1;
    grid-row-end: span 30;
  }

  .wear {
    grid-row-start: 39;
    grid-row-end: span 6;
    grid-column-start: 1;
    grid-column-end: span 3;
  }

  .activities {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 31;
    grid-row-end: span 8;
  }

  .rsvp {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 45;
    grid-row-end: span 10;
  }

  .contact {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 55;
    grid-row-end: span 5;
  }

  .addto {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 60;
    grid-row-end: span 3;
  }

  .help {
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 63;
    grid-row-end: span 10;
  }
}
